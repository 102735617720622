import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"   viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">



<path d="M1317 4473 c-4 -3 -7 -15 -7 -26 0 -17 5 -19 38 -13 20 3 52 6 70 6
25 0 32 4 32 20 0 18 -7 20 -63 20 -35 0 -67 -3 -70 -7z"/>
<path d="M1510 4460 c0 -18 7 -20 70 -20 63 0 70 2 70 20 0 18 -7 20 -70 20
-63 0 -70 -2 -70 -20z"/>
<path d="M1710 4460 c0 -18 7 -20 70 -20 63 0 70 2 70 20 0 18 -7 20 -70 20
-63 0 -70 -2 -70 -20z"/>
<path d="M1910 4460 c0 -18 7 -20 70 -20 63 0 70 2 70 20 0 18 -7 20 -70 20
-63 0 -70 -2 -70 -20z"/>
<path d="M2110 4460 c0 -18 7 -20 70 -20 63 0 70 2 70 20 0 18 -7 20 -70 20
-63 0 -70 -2 -70 -20z"/>
<path d="M2310 4460 c0 -18 7 -20 70 -20 63 0 70 2 70 20 0 18 -7 20 -70 20
-63 0 -70 -2 -70 -20z"/>
<path d="M2510 4460 c0 -18 7 -20 70 -20 63 0 70 2 70 20 0 18 -7 20 -70 20
-63 0 -70 -2 -70 -20z"/>
<path d="M2710 4460 c0 -18 7 -20 70 -20 63 0 70 2 70 20 0 18 -7 20 -70 20
-63 0 -70 -2 -70 -20z"/>
<path d="M2910 4460 c0 -18 7 -20 70 -20 63 0 70 2 70 20 0 18 -7 20 -70 20
-63 0 -70 -2 -70 -20z"/>
<path d="M3110 4460 c0 -18 7 -20 70 -20 63 0 70 2 70 20 0 18 -7 20 -70 20
-63 0 -70 -2 -70 -20z"/>
<path d="M3310 4460 c0 -18 7 -20 70 -20 63 0 70 2 70 20 0 18 -7 20 -70 20
-63 0 -70 -2 -70 -20z"/>
<path d="M3510 4460 c0 -17 7 -20 55 -21 30 -1 61 -2 68 -3 6 -1 13 9 15 22 3
21 0 22 -67 22 -64 0 -71 -2 -71 -20z"/>
<path d="M3704 4455 c-9 -21 -1 -27 54 -42 66 -17 82 -16 82 6 0 14 -12 22
-47 31 -27 7 -56 14 -66 17 -10 2 -20 -2 -23 -12z"/>
<path d="M1173 4442 c-39 -11 -53 -20 -53 -33 0 -26 10 -28 65 -12 28 8 56 17
63 19 6 2 12 13 12 24 0 24 -13 24 -87 2z"/>
<path d="M3888 4392 c-4 -15 7 -25 53 -46 56 -27 79 -28 79 -2 0 11 -99 66
-119 66 -5 0 -11 -8 -13 -18z"/>
<path d="M998 4365 c-55 -29 -63 -39 -44 -54 19 -16 128 36 124 59 -5 23 -29
22 -80 -5z"/>
<path d="M4061 4302 c-8 -15 0 -25 44 -59 48 -37 55 -40 67 -26 11 13 7 21
-27 50 -59 51 -72 57 -84 35z"/>
<path d="M843 4258 c-57 -50 -65 -64 -38 -74 8 -3 35 12 65 37 39 33 47 45 39
56 -16 19 -24 17 -66 -19z"/>
<path d="M2800 4199 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2880 4199 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M4204 4175 c-3 -8 12 -35 37 -64 35 -41 46 -48 61 -40 21 11 18 18
-39 82 -35 40 -50 46 -59 22z"/>
<path d="M699 4104 c-35 -45 -40 -57 -28 -65 22 -14 24 -12 67 43 30 39 38 56
30 65 -17 21 -25 16 -69 -43z"/>
<path d="M2610 4139 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2390 4029 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M4328 4027 c-15 -10 -14 -16 15 -69 31 -56 49 -69 62 -48 6 10 -36
104 -54 121 -3 4 -14 2 -23 -4z"/>
<path d="M622 3987 c-6 -7 -20 -35 -32 -62 -16 -38 -18 -50 -8 -57 7 -4 16 -8
20 -8 11 0 59 113 54 127 -7 17 -21 17 -34 0z"/>
<path d="M2293 3883 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M2578 3883 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z"/>
<path d="M2668 3873 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M2728 3863 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2778 3853 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4411 3851 c-12 -8 -11 -19 5 -75 13 -47 23 -65 32 -61 8 3 17 5 22
5 5 0 0 30 -11 67 -10 38 -19 69 -19 70 0 6 -16 2 -29 -6z"/>
<path d="M2040 3840 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M2000 3831 c0 -6 -9 -11 -20 -11 -11 0 -20 -4 -20 -10 0 -13 10 -12
39 4 15 8 20 16 12 20 -6 4 -11 2 -11 -3z"/>
<path d="M535 3768 c-20 -70 -19 -88 5 -88 13 0 22 9 26 28 3 15 10 42 16 59
10 35 5 53 -17 53 -8 0 -21 -21 -30 -52z"/>
<path d="M1820 3739 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M3157 3709 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M2317 3692 c-21 -23 -21 -41 -1 -70 18 -26 65 -30 84 -7 17 21 7 29
-18 14 -37 -23 -78 15 -51 47 9 11 22 14 42 10 20 -4 27 -2 25 7 -7 20 -62 20
-81 -1z"/>
<path d="M2576 3694 c-10 -25 -7 -94 4 -94 6 0 10 8 10 18 0 9 3 34 6 55 7 37
-8 53 -20 21z"/>
<path d="M2760 3670 c0 -5 5 -10 10 -10 7 0 8 -18 4 -50 -8 -62 11 -69 21 -9
5 29 13 43 26 46 28 8 23 20 -12 27 -42 8 -49 8 -49 -4z"/>
<path d="M4460 3661 c-12 -7 -14 -24 -12 -75 4 -63 5 -66 29 -66 25 0 26 1 19
53 -3 28 -6 62 -6 75 0 24 -6 26 -30 13z"/>
<path d="M1647 3653 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M1610 3624 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z"/>
<path d="M506 3599 c-3 -17 -6 -74 -6 -125 0 -94 0 -94 25 -94 l25 0 0 119 c0
99 -3 120 -16 125 -9 3 -17 6 -19 6 -1 0 -5 -14 -9 -31z"/>
<path d="M2993 3538 c-1 -56 1 -60 13 -43 21 31 28 39 55 58 l24 18 -31 -5
c-26 -4 -33 -1 -37 14 -10 40 -22 18 -24 -42z"/>
<path d="M1465 3488 l-60 -62 56 49 c47 42 76 75 66 75 -2 0 -29 -28 -62 -62z"/>
<path d="M3195 3434 c-33 -43 -26 -67 23 -72 22 -2 34 3 46 20 21 30 20 44 -4
68 -27 27 -33 26 -65 -16z m58 -13 c6 -23 -10 -41 -35 -41 -21 0 -23 13 -6 40
16 25 34 26 41 1z"/>
<path d="M4450 3395 c0 -73 1 -75 25 -75 24 0 25 2 25 75 0 73 -1 75 -25 75
-24 0 -25 -2 -25 -75z"/>
<path d="M1250 3450 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M1215 3430 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M1389 3403 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z"/>
<path d="M507 3324 c-4 -4 -7 -38 -7 -76 0 -66 1 -68 25 -68 26 0 26 1 23 72
-2 56 -7 73 -18 76 -9 1 -19 0 -23 -4z"/>
<path d="M1005 3320 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M970 3300 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M1291 3277 c-13 -21 -12 -21 5 -5 10 10 16 20 13 22 -3 3 -11 -5 -18
-17z"/>
<path d="M3645 3280 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M3395 3264 c-49 -18 -67 -34 -38 -34 25 0 40 -17 45 -50 3 -22 7 -19
31 27 41 79 37 84 -38 57z m29 -29 c-4 -8 -10 -12 -15 -9 -14 8 -10 24 6 24 9
0 12 -6 9 -15z"/>
<path d="M4450 3195 c0 -73 1 -75 25 -75 24 0 25 2 25 75 0 73 -1 75 -25 75
-24 0 -25 -2 -25 -75z"/>
<path d="M3670 3250 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M860 3190 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M513 3123 c-9 -3 -13 -28 -13 -74 0 -67 1 -69 25 -69 24 0 25 2 25
75 0 75 -4 82 -37 68z"/>
<path d="M820 3119 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M3580 3081 c0 -11 -15 -28 -37 -41 -21 -13 -32 -25 -26 -27 7 -3 28
5 46 16 25 15 36 18 45 9 20 -20 23 -2 5 31 -20 35 -33 40 -33 12z"/>
<path d="M4450 2995 c0 -73 1 -75 25 -75 24 0 25 2 25 75 0 73 -1 75 -25 75
-24 0 -25 -2 -25 -75z"/>
<path d="M1166 3045 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11 -11z"/>
<path d="M2347 2999 c-104 -17 -200 -90 -245 -189 -30 -64 -36 -165 -14 -241
31 -104 138 -197 254 -220 l58 -12 0 -138 c0 -164 -12 -232 -43 -248 -17 -8
19 -10 153 -8 241 5 291 25 351 144 25 50 32 78 36 147 7 98 -9 195 -39 246
-59 98 -114 119 -342 127 l-171 6 23 -26 c20 -21 25 -40 29 -106 7 -92 6 -93
-66 -60 -107 48 -173 164 -174 304 -1 163 78 255 218 255 73 0 138 -33 198
-99 l45 -51 16 40 c9 22 16 42 16 44 0 9 -98 65 -130 74 -53 16 -120 20 -173
11z m366 -442 c65 -50 97 -143 97 -285 0 -138 -43 -250 -113 -292 -25 -16 -50
-20 -118 -20 l-86 0 -7 78 c-8 85 -7 289 1 309 3 7 11 13 18 13 31 0 119 61
137 94 8 15 3 13 -24 -7 -37 -28 -115 -60 -128 -52 -4 3 -8 48 -9 101 l-2 96
99 -4 c90 -3 102 -5 135 -31z"/>
<path d="M507 2924 c-4 -4 -7 -38 -7 -76 0 -66 1 -68 25 -68 26 0 26 1 23 72
-2 56 -7 73 -18 76 -9 1 -19 0 -23 -4z"/>
<path d="M3850 2890 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M1110 2888 c0 -3 -9 -50 -21 -104 -40 -191 -36 -449 11 -636 10 -40
15 -79 12 -87 -4 -10 -1 -12 8 -6 10 6 12 3 8 -13 -4 -12 -2 -22 3 -22 5 0 9
-9 9 -19 0 -11 3 -21 8 -23 11 -5 82 -147 82 -163 0 -8 3 -15 8 -15 4 0 15
-17 25 -37 9 -21 27 -56 40 -78 13 -22 22 -46 20 -52 -3 -7 2 -13 12 -13 11 0
15 -5 11 -15 -3 -8 -1 -15 5 -15 6 0 58 -49 117 -110 58 -60 114 -112 124
-116 10 -3 16 -9 13 -14 -3 -5 -2 -11 3 -14 5 -3 8 9 7 27 0 18 -4 33 -8 35
-16 5 -19 1 -7 -13 8 -9 8 -15 2 -15 -6 0 -14 9 -17 20 -4 11 -11 18 -16 15
-5 -4 -9 -1 -9 5 0 7 -11 21 -25 32 -22 17 -24 23 -13 36 11 13 11 14 0 8 -23
-14 -41 12 -32 45 5 17 5 29 -1 29 -5 0 -9 7 -9 16 0 10 -6 14 -15 10 -10 -3
-15 1 -15 13 0 11 -13 30 -28 43 -15 12 -29 28 -31 33 -1 6 -10 18 -18 28 -10
10 -12 17 -4 17 8 0 8 5 -1 20 -10 15 -10 23 -2 28 15 10 9 22 -65 131 -192
284 -261 603 -196 910 8 41 13 76 10 79 -3 3 -5 3 -5 0z"/>
<path d="M4450 2795 c0 -73 1 -75 25 -75 24 0 25 2 25 75 0 73 -1 75 -25 75
-24 0 -25 -2 -25 -75z"/>
<path d="M3600 2840 c0 -5 6 -10 14 -10 19 0 27 -26 19 -58 -6 -25 -4 -24 40
13 64 53 61 65 -13 65 -33 0 -60 -4 -60 -10z m80 -20 c0 -5 -7 -10 -15 -10 -8
0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z"/>
<path d="M770 2740 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M500 2654 c0 -73 0 -74 25 -74 26 0 26 1 23 73 -3 67 -5 72 -25 73
-22 1 -23 -2 -23 -72z"/>
<path d="M3902 2705 c0 -11 4 -33 8 -50 7 -27 8 -27 8 -5 0 14 -4 36 -8 50 -6
20 -8 21 -8 5z"/>
<path d="M4450 2595 c0 -73 1 -75 25 -75 24 0 25 2 25 75 0 73 -1 75 -25 75
-24 0 -25 -2 -25 -75z"/>
<path d="M3922 2575 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M500 2456 c0 -75 0 -76 25 -76 26 0 26 1 23 73 -3 66 -5 72 -25 75
-22 3 -23 0 -23 -72z"/>
<path d="M3934 2415 c0 -60 1 -84 3 -52 2 32 2 81 0 110 -2 29 -3 3 -3 -58z"/>
<path d="M4457 2463 c-4 -3 -7 -37 -7 -75 0 -66 1 -68 25 -68 24 0 25 2 25 75
0 66 -2 75 -18 75 -10 0 -22 -3 -25 -7z"/>
<path d="M940 2380 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M513 2323 c-9 -3 -13 -28 -13 -74 0 -67 1 -69 25 -69 26 0 26 1 23
72 -3 69 -9 82 -35 71z"/>
<path d="M4450 2195 c0 -73 1 -75 25 -75 24 0 25 2 25 75 0 73 -1 75 -25 75
-24 0 -25 -2 -25 -75z"/>
<path d="M3922 2240 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M3911 2164 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M500 2056 c0 -75 0 -76 25 -76 26 0 26 1 23 73 -3 66 -5 72 -25 75
-22 3 -23 0 -23 -72z"/>
<path d="M4457 2063 c-4 -3 -7 -37 -7 -75 0 -66 1 -68 25 -68 24 0 25 2 25 75
0 66 -2 75 -18 75 -10 0 -22 -3 -25 -7z"/>
<path d="M500 1854 c0 -72 1 -74 25 -74 24 0 25 2 25 70 0 39 -3 70 -7 71 -5
0 -16 2 -25 4 -16 3 -18 -6 -18 -71z"/>
<path d="M4450 1795 c0 -73 1 -75 25 -75 24 0 25 2 25 75 0 73 -1 75 -25 75
-24 0 -25 -2 -25 -75z"/>
<path d="M507 1724 c-4 -4 -7 -38 -7 -76 0 -66 1 -68 25 -68 26 0 26 1 23 72
-2 56 -7 73 -18 76 -9 1 -19 0 -23 -4z"/>
<path d="M1395 1721 c-6 -11 13 -26 22 -17 4 3 0 6 -8 6 -11 0 -11 2 1 10 9 6
10 10 3 10 -6 0 -14 -4 -18 -9z"/>
<path d="M4450 1595 c0 -73 1 -75 25 -75 24 0 25 2 25 75 0 73 -1 75 -25 75
-24 0 -25 -2 -25 -75z"/>
<path d="M3995 1590 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M3960 1570 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M513 1523 c-8 -3 -13 -25 -13 -58 0 -67 7 -85 32 -85 17 0 19 6 16
72 -3 69 -9 82 -35 71z"/>
<path d="M1395 1490 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M4450 1431 c0 -21 -3 -53 -6 -70 -6 -28 -4 -31 18 -31 23 0 26 5 32
53 10 83 9 87 -19 87 -22 0 -25 -4 -25 -39z"/>
<path d="M1634 1355 c0 -19 3 -32 5 -29 8 7 9 54 2 59 -4 2 -7 -12 -7 -30z"/>
<path d="M1664 1345 c1 -14 8 -30 16 -37 12 -11 12 -10 3 2 -6 8 -9 23 -8 32
2 10 0 20 -4 23 -4 2 -7 -7 -7 -20z"/>
<path d="M521 1303 c1 -51 23 -108 42 -108 22 0 22 3 8 75 -10 49 -16 60 -32
60 -14 0 -19 -7 -18 -27z"/>
<path d="M1485 1310 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M1705 1289 c6 -9 3 -11 -8 -6 -24 9 -21 0 4 -14 15 -8 19 -8 15 0
-10 16 6 13 34 -6 20 -13 22 -14 13 -2 -7 9 -10 18 -7 21 3 3 0 9 -7 14 -8 4
-10 3 -5 -4 9 -15 1 -15 -26 -1 -16 9 -19 8 -13 -2z"/>
<path d="M3385 1280 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M3356 1271 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z"/>
<path d="M4413 1215 c-21 -64 -21 -65 -2 -76 17 -9 21 -5 33 23 24 58 30 100
15 109 -22 14 -24 12 -46 -56z"/>
<path d="M1520 1240 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M1780 1245 c0 -2 27 -18 60 -35 33 -17 60 -28 60 -25 0 2 -27 18 -60
35 -33 17 -60 28 -60 25z"/>
<path d="M3208 1183 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3100 1153 c-72 -20 -100 -23 -185 -20 -56 3 -119 12 -143 21 -37 13
-46 14 -58 2 -12 -12 -9 -16 18 -25 17 -6 40 -11 51 -11 11 0 17 -3 14 -7 -10
-9 -127 -10 -127 -1 0 4 -8 8 -17 8 -16 -1 -16 -2 1 -15 11 -8 29 -15 40 -16
19 -1 18 -2 -3 -8 l-23 -6 23 -12 c13 -6 33 -15 44 -19 15 -6 8 -9 -30 -14
-44 -5 -46 -6 -21 -12 17 -3 80 4 148 18 66 14 124 22 129 19 5 -4 12 1 15 10
4 9 13 14 20 11 8 -3 14 -1 14 4 0 6 9 10 19 10 11 0 21 3 23 8 5 11 106 62
123 62 8 0 15 5 15 10 0 6 -1 9 -2 9 -2 -1 -41 -12 -88 -26z"/>
<path d="M580 1140 c-12 -7 -10 -18 15 -69 27 -57 46 -74 59 -52 6 9 -45 131
-54 131 -3 0 -12 -4 -20 -10z"/>
<path d="M4341 1039 c-26 -49 -30 -63 -20 -70 23 -14 32 -5 63 55 25 48 28 59
15 67 -21 13 -22 13 -58 -52z"/>
<path d="M1610 1060 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M2248 1063 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2378 1063 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2493 1063 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M2298 1053 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2428 1053 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2338 1043 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2488 1043 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M2398 1033 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M2498 1023 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
<path d="M2368 1013 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2578 1003 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M665 960 c-7 -12 -2 -22 43 -78 31 -38 37 -41 50 -28 13 13 10 20
-22 65 -36 49 -58 62 -71 41z"/>
<path d="M4256 908 c-10 -13 -30 -35 -44 -51 -24 -25 -25 -29 -10 -43 14 -14
18 -14 38 8 12 12 34 38 48 56 21 28 23 35 11 43 -20 12 -22 12 -43 -13z"/>
<path d="M1981 873 c0 -6 -4 -20 -7 -30 -5 -14 -4 -15 5 -7 7 7 11 20 8 30 -3
11 -5 14 -6 7z"/>
<path d="M1840 849 c-7 -11 -10 -23 -7 -25 2 -2 9 7 15 21 14 30 7 33 -8 4z"/>
<path d="M2372 860 c3 -8 19 -18 36 -22 33 -9 41 -25 20 -46 -8 -8 -8 -12 -1
-12 11 0 23 23 23 45 0 7 -15 17 -32 20 -18 4 -37 13 -41 19 -6 7 -7 6 -5 -4z"/>
<path d="M2215 850 c2 -4 19 -10 36 -14 25 -5 30 -10 26 -26 -3 -11 -1 -18 4
-14 5 3 9 13 9 23 0 13 -12 22 -40 29 -22 5 -38 6 -35 2z"/>
<path d="M790 815 c-10 -12 -6 -21 26 -50 56 -51 70 -57 84 -40 10 12 4 23
-36 59 -55 51 -57 52 -74 31z"/>
<path d="M1830 790 c0 -13 11 -13 30 0 12 8 11 10 -7 10 -13 0 -23 -4 -23 -10z"/>
<path d="M4100 754 c-43 -34 -57 -63 -34 -71 6 -2 35 15 63 37 43 34 50 44 40
56 -15 19 -17 18 -69 -22z"/>
<path d="M1865 770 c-8 -13 5 -13 25 0 13 8 13 10 -2 10 -9 0 -20 -4 -23 -10z"/>
<path d="M1906 765 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11 -11z"/>
<path d="M2028 773 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2390 770 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M1937 745 c3 -8 9 -12 14 -9 12 7 11 11 -5 17 -10 4 -13 1 -9 -8z"/>
<path d="M2010 709 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M944 696 c-3 -8 -3 -20 1 -26 10 -16 105 -61 115 -55 28 17 -9 51
-102 93 -5 2 -11 -3 -14 -12z"/>
<path d="M3933 652 c-48 -24 -65 -47 -47 -65 4 -3 33 7 66 24 41 21 58 35 56
46 -5 25 -18 24 -75 -5z"/>
<path d="M1116 604 c-8 -22 -1 -28 54 -43 25 -7 53 -15 63 -17 13 -4 17 1 17
19 0 19 -7 25 -42 34 -24 6 -53 14 -64 18 -15 5 -23 2 -28 -11z"/>
<path d="M3785 595 c-5 -2 -29 -9 -52 -15 -41 -10 -56 -31 -33 -45 8 -5 129
21 138 29 1 2 0 11 -4 20 -6 15 -26 19 -49 11z"/>
<path d="M1302 549 c3 -22 8 -24 71 -27 62 -3 67 -1 67 18 0 14 -6 20 -24 20
-13 0 -44 3 -70 6 -46 7 -48 6 -44 -17z"/>
<path d="M1500 540 c0 -18 7 -20 70 -20 63 0 70 2 70 20 0 18 -7 20 -70 20
-63 0 -70 -2 -70 -20z"/>
<path d="M1700 540 c0 -18 7 -20 70 -20 63 0 70 2 70 20 0 18 -7 20 -70 20
-63 0 -70 -2 -70 -20z"/>
<path d="M1900 540 c0 -18 7 -20 70 -20 63 0 70 2 70 20 0 18 -7 20 -70 20
-63 0 -70 -2 -70 -20z"/>
<path d="M2100 540 c0 -18 7 -20 70 -20 63 0 70 2 70 20 0 18 -7 20 -70 20
-63 0 -70 -2 -70 -20z"/>
<path d="M2300 540 c0 -18 7 -20 69 -20 50 0 70 4 74 14 9 22 -2 26 -75 26
-61 0 -68 -2 -68 -20z"/>
<path d="M2500 540 c0 -18 7 -20 70 -20 63 0 70 2 70 20 0 18 -7 20 -70 20
-63 0 -70 -2 -70 -20z"/>
<path d="M2700 540 c0 -18 7 -20 69 -20 50 0 70 4 74 14 9 22 -2 26 -75 26
-61 0 -68 -2 -68 -20z"/>
<path d="M2900 540 c0 -18 7 -20 68 -20 73 0 84 4 75 26 -4 10 -24 14 -74 14
-62 0 -69 -2 -69 -20z"/>
<path d="M3100 540 c0 -18 7 -20 69 -20 50 0 70 4 74 14 9 22 -2 26 -75 26
-61 0 -68 -2 -68 -20z"/>
<path d="M3300 540 c0 -20 5 -21 73 -18 56 2 72 6 72 18 0 12 -16 16 -72 18
-68 3 -73 2 -73 -18z"/>
<path d="M3500 540 c0 -18 7 -20 70 -20 63 0 70 2 70 20 0 18 -7 20 -70 20
-63 0 -70 -2 -70 -20z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
